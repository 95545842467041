import {
  addOrderCoupon,
  addOrderCouponSuccess,
  addOrderCouponFailure,
  createNewPendingOrderItem,
  setPendingOrderItem,
  addPendingOrderItemToCart,
  changeOrderItemCount,
  removeOrderItem,
  setOrderComment,
  removeOrderCoupon,
} from './cart.actions';
import { ProbeArgument } from '../createStore';
import { AddOrderCouponPayload, CreateNewPendingOrderItemPayload } from './cart.actions.types';
import { couponChargesSelector } from '../selectors/couponChargesSelector';
import { saveStateToSessionStorage } from '../session/session.actions';
import sha1 from 'js-sha1';
import { Action, createOrderItem, getDisplayableCouponError } from '@wix/restaurants-client-logic';

export default function cartProbe({ onAction }: ProbeArgument) {
  onAction(
    createNewPendingOrderItem.toString(),
    async (action: Action<CreateNewPendingOrderItemPayload>, getState, dispatch) => {
      const { itemId } = action.payload;
      const { itemsHash } = getState().session;
      const orderItem = createOrderItem({ itemId, itemsHash });
      dispatch(setPendingOrderItem({ orderItem, isNew: true }));
    },
  );

  onAction(addPendingOrderItemToCart.toString(), (action, getState, dispatch) => {
    dispatch(saveStateToSessionStorage());
  });

  onAction(changeOrderItemCount.toString(), (action, getState, dispatch) => {
    dispatch(saveStateToSessionStorage());
  });

  onAction(removeOrderItem.toString(), (action, getState, dispatch) => {
    dispatch(saveStateToSessionStorage());
  });
  onAction(removeOrderCoupon.toString(), (action, getState, dispatch, { flowAPI }) => {
    const coupon = getState().cart.coupon;
    if (flowAPI.biLogger) {
      flowAPI.biLogger.cartPromoCodeRemove({
        promoCode: coupon && coupon.type === 'success' ? coupon.code : 'failedCode',
      });
    }
  });

  onAction(setOrderComment.toString(), (action, getState, dispatch) => {
    dispatch(saveStateToSessionStorage());
  });

  onAction(
    addOrderCoupon.toString(),
    async (action: Action<AddOrderCouponPayload>, getState, dispatch, { flowAPI }) => {
      const { coupon } = action.payload;
      const couponHashCode: string = sha1(coupon);

      const state = getState();
      const couponCharge = couponChargesSelector({
        ...state,
        cart: {
          ...state.cart,
          coupon: {
            type: 'success',
            code: coupon,
            token: '',
            couponHashCode,
          },
        },
      });
      if (couponCharge?.error) {
        flowAPI.biLogger &&
          flowAPI.biLogger.cartPromoCodeApplyFailure({ errorReason: couponCharge.error.reason, promoCode: coupon });
        dispatch(
          addOrderCouponFailure({
            type: 'failure',
            message: getDisplayableCouponError(
              couponCharge.error,
              state.session.restaurant.locale,
              state.session.restaurant.currency,
            ),
          }),
        );
        return;
      }

      const restaurantId = state.session.restaurant.id;

      try {
        const response = await fetch(
          `https://codeusages.wixrestaurants.com/v1/prefixes/${restaurantId}/codes/${coupon}/generateToken`,
          {
            method: 'POST',
          },
        );
        const { token } = await response.json();

        if (!token) {
          dispatch(addOrderCouponFailure({ type: 'failure', message: 'invalid_promo_code' }));
          return;
        }

        dispatch(addOrderCouponSuccess({ type: 'success', token, code: coupon, couponHashCode }));
        if (flowAPI.biLogger) {
          flowAPI.biLogger.cartPromoCodeApplySuccess({
            promoCode: coupon,
          });
        }
        dispatch(saveStateToSessionStorage());
      } catch (e) {
        dispatch(addOrderCouponFailure({ type: 'failure', message: 'invalid_promo_code' }));
      }
    },
  );
}
