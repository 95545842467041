import moment from 'moment-timezone';
import {
  abortSmsVerification,
  setCashierPayment,
  setCashierPaymentDone,
  setCashierToken,
  setContact,
  setContactId,
  setDeliveryAddress,
  setDeliveryAddressFromForm,
  setDispatchTime,
  setDispatchType,
  setPayment,
  setSmsError,
  setSmsLoading,
  submitOrder,
  submitSmsCode,
} from './checkout.actions';
import { ProbeArgument } from '../createStore';
import { orderSelector } from '../selectors/orderSelector';
import { cartSummarySelector } from '../selectors/cartSummarySelector';
import { closeModal, initApp, openModal } from '../session/session.actions';
import { Modals } from '../../core/constants';
import { addPendingOrderItemToCart, removeOrderCoupon } from '../cart/cart.actions';
import { SetCashierPaymentPayload, SetContactPayload } from './checkout.actions.types';
import {
  extractCashierResponse,
  getCashierCallbackUrl,
  isCashierPending,
  parseCashierCallbackParams,
} from '../../core/logic/cashierLogic';
import { ServerTransactionStatus } from '@wix/cashier-common/dist/src/enums/transactions/ServerTransactionStatus';
import uuid from 'uuid';
import {
  Action,
  CashierPayment,
  Order,
  OrderFailureResponse,
  BusinessNotifications,
  getDisplayableMenu,
  getDefaultDispatchType,
  getDateOptions,
  getTimeOptions,
  shouldVerifyOrderWithSms,
  Contact,
} from '@wix/restaurants-client-logic';
import { submitSmsVerification } from '../../core/oloApi';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { businessNotificationSelector } from '../selectors/businessNotificationSelector';
import { handleOrderFailure, handleOrderSuccess, reportSubmitOrderBiEvent } from './checkout.probe.utils';
import { RestaurantsContacts, SubmitContactRequest } from '@wix/ambassador-restaurants-contacts/http';
import _ from 'lodash';

export default function checkoutProbe({ onAction, waitForAction }: ProbeArgument) {
  onAction(submitOrder.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const rawOrder = orderSelector(getState());
    const order = { ...rawOrder, contact: decorateContactWithWixIds(rawOrder.contact, flowAPI) };
    const { itemCount } = cartSummarySelector(getState());
    const restaurant = getState().session.restaurant;
    const requestId = uuid.v4();
    const isContactless = Boolean(getState().checkout.isContactlessDeliveryChecked);

    reportSubmitOrderBiEvent(flowAPI, restaurant, requestId, order.price, itemCount, isContactless);

    const coupon = getState().cart.coupon;

    if (coupon?.type === 'success') {
      try {
        const response = await fetch(
          `https://codeusages.wixrestaurants.com/v1/prefixes/${restaurant.id}/codes/${coupon.code}/tokens/${coupon.token}/canUse`,
        );
        const { canUse } = await response.json();

        if (!canUse) {
          dispatch(removeOrderCoupon());
          dispatch(openModal({ modal: Modals.ORDER_FAILURE_MODAL }));
          return;
        }
      } catch (e) {
        dispatch(removeOrderCoupon());
        dispatch(openModal({ modal: Modals.ORDER_FAILURE_MODAL }));
        return;
      }
    }

    let response;

    try {
      response = await fetch(`https://api.wixrestaurants.com/v2/organizations/${restaurant.id}/orders`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(order),
      });
    } catch (e) {
      handleOrderFailure({
        orderResponseType: 'no_server_response',
        dispatch,
        flowAPI,
        requestId,
      });
      return;
    }

    if (response.ok) {
      const orderResponse: Order = await response.json();

      if (orderResponse.status === 'pending') {
        const cashierResponse = extractCashierResponse(orderResponse);

        if (cashierResponse.status === ServerTransactionStatus.InProcess) {
          dispatch(setCashierToken({ token: cashierResponse.responseToken }));
          const { payload }: ReturnType<typeof setCashierPaymentDone> = await waitForAction(
            setCashierPaymentDone.toString(),
          );
          const continueStatus = payload.paymentResult.status;

          if (continueStatus === ServerTransactionStatus.InProcess) {
            return;
          }

          if (
            continueStatus &&
            (continueStatus === ServerTransactionStatus.Approved || isCashierPending(continueStatus))
          ) {
            await fetch(
              `https://api.wixrestaurants.com/v2/organizations/${restaurant.id}/orders/${orderResponse.id}/confirmCashier?as=customer`,
              {
                method: 'POST',
              },
            );
          } else {
            dispatch(openModal({ modal: Modals.ORDER_FAILURE_MODAL }));
            return;
          }
        }
      }

      if (shouldVerifyOrderWithSms(orderResponse)) {
        let isSmsVerificationSuccessful = false;
        dispatch(openModal({ modal: Modals.SMS_MODAL }));
        reportSmsBiEvent(flowAPI, 'display');

        while (!isSmsVerificationSuccessful) {
          const smsAction = await waitForAction([submitSmsCode.toString(), abortSmsVerification.toString()]);

          if (smsAction.type === submitSmsCode.toString()) {
            const code = smsAction.payload.code;
            dispatch(setSmsLoading({ loading: true }));
            isSmsVerificationSuccessful = await submitSmsVerification(restaurant.id, orderResponse.id, code);
            dispatch(setSmsLoading({ loading: false }));

            if (isSmsVerificationSuccessful) {
              dispatch(closeModal({ modal: Modals.SMS_MODAL }));
              reportSmsBiEvent(flowAPI, 'success');
            } else {
              dispatch(setSmsError({ hasSmsError: true }));
              reportSmsBiEvent(flowAPI, 'failure');
            }
          } else if (smsAction.type === abortSmsVerification.toString()) {
            // TODO: BI event
            return;
          }
        }
      }

      handleOrderSuccess({
        orderResponse,
        dispatch,
        flowAPI,
        requestId,
      });
    } else {
      const orderResponse: OrderFailureResponse = await response.json();
      const { chargeId } = orderResponse.params || {};

      handleOrderFailure({
        orderResponseType: orderResponse.type,
        orderResponseDetail: orderResponse.detail,
        orderResponseCode: orderResponse.errorCode,
        orderResponseChargeId: chargeId,
        dispatch,
        flowAPI,
        requestId,
      });
    }
  });

  onAction(
    setCashierPayment.toString(),
    async (action: Action<SetCashierPaymentPayload>, getState, dispatch, { flowAPI }) => {
      const { instance } = getState().platformParams;
      const { paymentMethod, paymentMethodTitle = '' } = getState().checkout.pendingCashierPayment;
      const { priceComponents } = cartSummarySelector(getState());
      const accountId = `${instance.appDefId}:${instance.instanceId}`;
      const { paymentDetailsId, creditCard } = action.payload;
      if (paymentMethod) {
        const payment: CashierPayment = {
          type: 'cashier',
          paymentMethod,
          paymentMethodTitle,
          paymentDetailsId,
          accountId,
          amount: priceComponents.total,
          creditCard,
          returnUrl: getCashierCallbackUrl(flowAPI),
        };

        dispatch(
          setPayment({
            payment,
          }),
        );
      }
    },
  );

  onAction(addPendingOrderItemToCart.toString(), (action, getState, dispatch, { flowAPI }) => {
    const state = getState();
    const { payments } = state.checkout;
    const { orderItems } = state.cart;
    const lastItemDetails = orderItems[orderItems.length - 1];
    const { priceComponents } = cartSummarySelector(getState());

    if (flowAPI.biLogger) {
      flowAPI.biLogger.addToCart({ dishId: lastItemDetails.itemId, quantity: lastItemDetails.count });
    }

    if (payments.length === 1) {
      dispatch(
        setPayment({
          payment: {
            ...payments[0],
            amount: priceComponents.total,
          },
        }),
      );
    }
  });

  onAction(setDeliveryAddressFromForm.toString(), (action, getState, dispatch) => {
    dispatch(
      setDeliveryAddress({
        address: getState().addressForm.selectedAddressOption,
      }),
    );
  });

  onAction(initApp.toString(), async (action, getState, dispatch, { flowAPI }) => {
    const state = getState();
    const { restaurant, menu } = state.session;
    const { overrideDispatchType } = state.checkout;
    const dispatchTime = state.checkout.dispatch.time || Date.now();
    const platform = state.platformParams.isMobile ? 'mobileweb' : 'web';
    const displayableMenu = getDisplayableMenu(
      menu,
      restaurant.locale,
      restaurant.currency,
      moment(dispatchTime),
      platform,
      'delivery',
    );

    let dispatchType = getDefaultDispatchType({ restaurant, dispatchTime, displayableMenu });
    if (overrideDispatchType) {
      dispatchType = state.checkout.dispatch.type;
    }

    const businessNotification = businessNotificationSelector(state);

    if (
      restaurant.orders.asap.disabled ||
      businessNotification?.notification === BusinessNotifications.OnlyFutureOrders
    ) {
      const dateOptions = getDateOptions({
        availability: restaurant.openTimes,
        timezone: restaurant.timezone,
        delayMins: restaurant.orders.future.delayMins,
      });
      const timeOptions = getTimeOptions({
        availability: restaurant.openTimes,
        timezone: restaurant.timezone,
        locale: restaurant.locale,
        day: moment(dateOptions[0].timestamp).tz(restaurant.timezone).startOf('day').valueOf(),
        delayMins: restaurant.orders.future.delayMins,
      });
      dispatch(
        setDispatchTime({
          timestamp: timeOptions[0].timestamp,
        }),
      );
    }

    dispatch(setDispatchType({ dispatchType }));

    await handleCashierWalletCallback(flowAPI, dispatch);
  });

  onAction(setContact.toString(), async (action: Action<SetContactPayload>, getState, dispatch, { flowAPI }) => {
    const { firstName, lastName, email, phone } = action.payload.contact;

    const experiments = await flowAPI.getExperiments();
    const shouldSubmitContact = experiments.enabled('specs.restaurants.SubmitContactsV4');

    if (!shouldSubmitContact) {
      return;
    }

    const signedInstance = getState().platformParams.signedInstance;
    const headers = { Authorization: signedInstance };
    const restaurantsContacts = RestaurantsContacts('/_api/restaurants').RestaurantsContacts()(headers);
    const submitContactRequest: SubmitContactRequest = { contact: { email, firstName, lastName, phone } };

    const { contactId } = await restaurantsContacts.submitContact(submitContactRequest);

    if (contactId) {
      dispatch(setContactId({ wixContactId: contactId }));
    }
  });
}

function reportSmsBiEvent(flowAPI: ControllerFlowAPI, type: 'display' | 'success' | 'failure') {
  if (flowAPI.biLogger) {
    switch (type) {
      case 'display':
        flowAPI.biLogger.smsValidationDisplay({});
        break;
      case 'failure':
        flowAPI.biLogger.smsValidationFailure({});
        break;
      case 'success':
        flowAPI.biLogger.smsValidationSuccess({});
        break;
      default:
        break;
    }
  }
}

async function handleCashierWalletCallback(flowAPI: ControllerFlowAPI, dispatch: any) {
  const { isCashierCallback, restaurantId, orderId, ownerToken, status } = parseCashierCallbackParams(flowAPI);

  if (!isCashierCallback) {
    return;
  }

  flowAPI.controllerConfig.wixCodeApi.location.queryParams.remove([
    'cashierCallback',
    'transactionStatus',
    'restaurantsId',
    'orderId',
    'ownerToken',
  ]);

  if (status !== ServerTransactionStatus.Approved) {
    handleOrderFailure({ orderResponseType: 'order_status_not_approved', dispatch, flowAPI });
    return;
  }

  let response;

  try {
    response = await fetch(
      `https://api.wixrestaurants.com/v2/organizations/${restaurantId}/orders/${orderId}?viewMode=customer`,
      {
        headers: {
          authorization: `Bearer ${ownerToken}`,
        },
      },
    );
  } catch (e) {
    handleOrderFailure({ orderResponseType: 'no_server_response', dispatch, flowAPI });
    return;
  }

  const orderResponse = await response.json();

  handleOrderSuccess({
    orderResponse,
    dispatch,
    flowAPI,
  });
}

function decorateContactWithWixIds(contact: Contact, flowAPI: ControllerFlowAPI): Contact {
  const result = _.cloneDeep(contact);

  if (result.wixContactId) {
    const { role, id } = flowAPI.controllerConfig.wixCodeApi.user.currentUser;

    if (role === 'Visitor') {
      result.wixVisitorId = id;
    } else {
      result.wixMemberId = id;
    }
  }

  return result;
}
