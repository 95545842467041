import { createSelector } from 'reselect';
import { AppState } from '../createStore';
import _ from 'lodash';
import { Address, BusinessNotifications, Restaurant, getAllBusinessNotifications } from '@wix/restaurants-client-logic';
import { DEFAULT_ADDRESS, NO_ADDRESS, DEFAULT_ADDRESS_SHORT } from '../../core/constants';

export interface BusinessNotificationDetails {
  notification: BusinessNotifications;
  comment?: string;
}

export function businessNotificationSelectorInner(
  restaurant: Restaurant,
  hasAnyPaymentMethods: boolean,
  dispatchTime?: number,
): BusinessNotificationDetails | undefined {
  const notifications = getAllBusinessNotifications(restaurant, hasAnyPaymentMethods, dispatchTime);
  return notifications.length > 0 ? notifications[0] : undefined;
}

export const isPremiumRestaurant = (restaurant: Restaurant) =>
  _.some(restaurant.products, {
    id: 'com.wix.restaurants.orders',
    param: 'premium',
  });

export const isNoPhysicalLocation = (address: Address) => {
  return (
    address &&
    (address.formatted === NO_ADDRESS ||
      address.formatted === DEFAULT_ADDRESS ||
      address.formatted === DEFAULT_ADDRESS_SHORT)
  );
};

export const businessNotificationSelector = createSelector(
  (state: AppState) => state.session.restaurant,
  (state: AppState) => state.session.paymentMethods.length > 0,
  businessNotificationSelectorInner,
);

export const businessNotificationSelectorByDispatchTime = createSelector(
  [
    (state: AppState) => state.session.restaurant,
    (state: AppState) => state.session.paymentMethods.length > 0,
    (state: AppState) => state.checkout.dispatch.time,
  ],
  businessNotificationSelectorInner,
);

export const allBusinessNotificationsSelectorByDispatchTime = createSelector(
  [
    (state: AppState) => state.session.restaurant,
    (state: AppState) => state.session.paymentMethods.length > 0,
    (state: AppState) => state.checkout.dispatch.time,
  ],
  getAllBusinessNotifications,
);
