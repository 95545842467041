import { Action, Contact, Order, OrderFailureType, Restaurant } from '@wix/restaurants-client-logic';
import { Dispatch } from 'redux';
import { ControllerFlowAPI } from 'yoshi-flow-editor-runtime/build/cjs/flow-api/ViewerScript';
import { setErrorType, submitOrderSuccess } from './checkout.actions';
import { clearSessionStorage, navigate, openModal } from '../session/session.actions';
import { Modals, RouteUrls } from '../../core/constants';
import { GetMyMemberResponse, MembersNgApi, Contact as MembersApiContact } from '@wix/ambassador-members-ng-api/http';
import _ from 'lodash';

export function handleOrderSuccess({
  orderResponse,
  dispatch,
  flowAPI,
  requestId = '',
}: {
  orderResponse: Order;
  dispatch: Dispatch<Action<any>>;
  flowAPI: ControllerFlowAPI;
  requestId?: string;
}) {
  dispatch(submitOrderSuccess({ orderResponse }));
  dispatch(navigate({ routeUrl: RouteUrls.THANK_YOU }));
  dispatch(clearSessionStorage());
  if (flowAPI.biLogger) {
    flowAPI.biLogger.submitOrderSuccess({
      orderId: orderResponse.id,
      oloRequestId: requestId,
    });
  }
  fillInMissingContactDataInMembersArea(flowAPI, orderResponse.contact);
}

export function handleOrderFailure({
  orderResponseType = '',
  orderResponseDetail = '',
  orderResponseCode,
  orderResponseChargeId,
  dispatch,
  flowAPI,
  requestId = '',
}: {
  orderResponseType?: string;
  orderResponseDetail?: string;
  orderResponseCode?: number;
  orderResponseChargeId?: string;
  dispatch: Dispatch<Action<any>>;
  flowAPI: ControllerFlowAPI;
  requestId?: string;
}) {
  let errorType = '';
  if (orderResponseType) {
    errorType = orderResponseType.substring(orderResponseType.lastIndexOf('/') + 1);
  }
  dispatch(
    setErrorType({
      errorType: errorType as OrderFailureType,
      errorCode: orderResponseCode,
      errorChargeId: orderResponseChargeId,
    }),
  );

  dispatch(openModal({ modal: Modals.ORDER_FAILURE_MODAL }));
  if (flowAPI.biLogger) {
    flowAPI.biLogger.submitOrderFailure({
      rejectReason: orderResponseType,
      oloRequestId: requestId,
    });
    if (orderResponseDetail && orderResponseDetail.includes('earliest allowed delivery.time')) {
      flowAPI.biLogger.futureOrdersError({ details: orderResponseDetail.split('order')[0] });
    }
  }
}

export function reportSubmitOrderBiEvent(
  flowAPI: ControllerFlowAPI,
  restaurant: Restaurant,
  requestId: string,
  total: number,
  totalItemsCount: number,
  isContactless: boolean,
) {
  if (flowAPI.biLogger) {
    flowAPI.biLogger.submitOrder({
      currency: restaurant.currency,
      oloRequestId: requestId,
      total,
      totalItemsCount,
      isContactless,
    });
  }
}

export async function fillInMissingContactDataInMembersArea(flowAPI: ControllerFlowAPI, oloContact: Contact) {
  if (!flowAPI.controllerConfig.wixCodeApi.user.currentUser.loggedIn) {
    return;
  }

  const experiments = await flowAPI.getExperiments();
  const isExperimentEnabled = experiments.enabled('specs.restaurants.olo-client-members-area');

  if (!isExperimentEnabled) {
    return;
  }

  const signedInstance = flowAPI.controllerConfig.appParams.instance;
  const headers = { Authorization: signedInstance };
  const membersApi = MembersNgApi('/_api/members').Members()(headers);
  const { member }: GetMyMemberResponse = await membersApi.getMyMember({ fieldSet: 'FULL' });

  if (member && member.contact) {
    const { shouldBeUpdated, contact } = constructContactToBeUpdated(member.contact, oloContact);

    if (shouldBeUpdated) {
      await membersApi.updateMember({
        member: { id: member.id, contact },
      });
    }
  }
}

function constructContactToBeUpdated(membersApiContact: MembersApiContact, oloContact: Contact) {
  let shouldBeUpdated = false;
  const contact: MembersApiContact = {};

  if (!membersApiContact.firstName) {
    shouldBeUpdated = true;
    contact.firstName = oloContact.firstName;
  }

  if (!membersApiContact.lastName) {
    shouldBeUpdated = true;
    contact.lastName = oloContact.lastName;
  }

  if (_.isEmpty(membersApiContact.phones)) {
    shouldBeUpdated = true;
    contact.phones = [oloContact.phone];
  }

  return {
    shouldBeUpdated,
    contact,
  };
}
