import { closeModal, navigate, openModal, setOrganizationFull, setPaymentMethods } from './session.actions';
import { createReducer, pushItem, removeItem } from '../common/reducerUtils';
import { EMPTY_MENU, EMPTY_RESTAURANT } from '../../core/constants';
import {
  CloseModalPayload,
  NavigatePayload,
  OpenModalPayload,
  SetPaymentMethodsPayload,
} from './session.actions.types';
import { ExtractPayload, getItemsHash, ItemsHash, Menu, Restaurant } from '@wix/restaurants-client-logic';
import { IPaymentMethod } from '@wix/cashier-payments-widget';

interface SessionState {
  restaurant: Restaurant;
  menu: Menu;
  itemsHash: ItemsHash;
  modals: OpenModalPayload[];
  routeUrlOverride?: string;
  paymentMethods: IPaymentMethod[];
}

const initialState: SessionState = {
  restaurant: EMPTY_RESTAURANT,
  menu: EMPTY_MENU,
  itemsHash: {},
  modals: [],
  paymentMethods: [],
};

const reducer = createReducer<SessionState>(initialState, {
  [setOrganizationFull.toString()]: (state, payload: ExtractPayload<typeof setOrganizationFull>) => {
    const { restaurant, menu } = payload.organizationFull;
    return {
      ...state,
      restaurant,
      menu,
      itemsHash: getItemsHash(menu),
    };
  },
  [openModal.toString()]: (state, payload: OpenModalPayload) => {
    return {
      ...state,
      modals: pushItem(state.modals, payload),
    };
  },
  [closeModal.toString()]: (state, payload: CloseModalPayload) => {
    return {
      ...state,
      modals: removeItem(
        state.modals,
        state.modals.findIndex(({ modal }) => modal === payload.modal),
      ),
      routeUrlOverride: undefined,
    };
  },
  [navigate.toString()]: (state, payload: NavigatePayload) => {
    return {
      ...state,
      routeUrlOverride: payload.routeUrl,
    };
  },
  [setPaymentMethods.toString()]: (state, payload: SetPaymentMethodsPayload) => {
    return {
      ...state,
      paymentMethods: payload.paymentMethods,
    };
  },
});

export default reducer;
