import {
  abortSmsVerification,
  clearOrderResponse,
  initCheckout,
  setCashierPayment,
  setCashierPaymentDone,
  setCashierToken,
  setCheckoutStep,
  setContact,
  setContactField,
  setContactId,
  setContactlessDelivery,
  setDeliveryAddress,
  setDispatch,
  setDispatchTime,
  setDispatchType,
  setErrorType,
  setPayment,
  setPendingCashierPayment,
  setSmsError,
  setSmsLoading,
  setTip,
  submitOrder,
  submitOrderSuccess,
  submitSmsCode,
} from './checkout.actions';
import { createReducer } from '../common/reducerUtils';
import { EMPTY_ADDRESS } from '../../core/constants';
import {
  SetCashierTokenPayload,
  SetCheckoutStepPayload,
  SetContactFieldPayload,
  SetContactIdPayload,
  SetContactlessDeliveryPayload,
  SetContactPayload,
  SetDeliveryAddressPayload,
  SetDispatchPayload,
  SetDispatchTimePayload,
  SetDispatchTypePayload,
  SetErrorTypePayload,
  SetPaymentPayload,
  SetPendingCashierPaymentPayload,
  SetSmsLoadingPayload,
  SetTipPayload,
  SubmitOrderSuccessPayload,
} from './checkout.actions.types';
import {
  ActionHandlers,
  CashierPayment,
  Contact,
  Dispatch,
  Order,
  OrderFailureType,
  Payment,
  Tip,
} from '@wix/restaurants-client-logic';
import { CheckoutStep } from '../../core/types/Checkout';

interface CheckoutState {
  contact: Contact;
  dispatch: Dispatch;
  payments: Payment[];
  pendingCashierPayment: Partial<CashierPayment>;
  orderResponse?: Order;
  isContactlessDeliveryChecked?: boolean;
  cashierToken?: string;
  tip?: Tip;
  checkoutStep: CheckoutStep;
  isLoading?: boolean;
  hasSmsError?: boolean;
  isSmsLoading?: boolean;
  errorType?: OrderFailureType;
  errorCode?: number;
  errorChargeId?: string;
  overrideDispatchType?: boolean;
}

const initialState: CheckoutState = {
  contact: { firstName: '', lastName: '', phone: '', email: '' },
  dispatch: { type: 'delivery', address: EMPTY_ADDRESS },
  payments: [],
  pendingCashierPayment: {},
  checkoutStep: 'address-information',
};

const handlers: ActionHandlers<CheckoutState> = {
  [setContact.toString()]: (state, payload: SetContactPayload) => {
    return {
      ...state,
      contact: payload.contact,
    };
  },
  [setContactId.toString()]: (state, payload: SetContactIdPayload) => {
    return {
      ...state,
      contact: {
        ...state.contact,
        wixContactId: payload.wixContactId,
      },
    };
  },
  [initCheckout.toString()]: () => {
    return initialState;
  },
  [setDispatchType.toString()]: (state, payload: SetDispatchTypePayload) => {
    return {
      ...state,
      dispatch: {
        ...state.dispatch,
        type: payload.dispatchType,
      } as Dispatch,
    };
  },
  [setDispatch.toString()]: (state, payload: SetDispatchPayload) => {
    return {
      ...state,
      dispatch: payload.dispatch,
      overrideDispatchType: true,
    };
  },
  [setTip.toString()]: (state, { tip }: SetTipPayload) => {
    return {
      ...state,
      tip,
    };
  },
  [setCheckoutStep.toString()]: (state, { step }: SetCheckoutStepPayload) => {
    return {
      ...state,
      checkoutStep: step,
      isLoading: false,
    };
  },
  [setDispatchTime.toString()]: (state, payload: SetDispatchTimePayload) => {
    return {
      ...state,
      dispatch: {
        ...state.dispatch,
        time: payload.timestamp,
      },
    };
  },
  [setDeliveryAddress.toString()]: (state, payload: SetDeliveryAddressPayload) => {
    return {
      ...state,
      dispatch: {
        ...state.dispatch,
        address: payload.address,
      },
    };
  },
  [setContactField.toString()]: (state, payload: SetContactFieldPayload) => {
    return {
      ...state,
      contact: {
        ...state.contact,
        [payload.field]: payload.value,
      },
    };
  },
  [setPayment.toString()]: (state, payload: SetPaymentPayload) => {
    return {
      ...state,
      payments: [payload.payment],
      isLoading: false,
    };
  },
  [setPendingCashierPayment.toString()]: (state, payload: SetPendingCashierPaymentPayload) => {
    return {
      ...state,
      pendingCashierPayment: {
        ...state.pendingCashierPayment,
        ...payload.payment,
        paymentMethodTitle: payload.paymentMethodTitle,
      },
    };
  },
  [submitOrder.toString()]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [submitOrderSuccess.toString()]: (state, { orderResponse }: SubmitOrderSuccessPayload) => {
    return {
      ...state,
      orderResponse,
      isLoading: false,
    };
  },
  [setContactlessDelivery.toString()]: (state, { isContactless }: SetContactlessDeliveryPayload) => {
    return {
      ...state,
      isContactlessDeliveryChecked: isContactless,
    };
  },
  [setCashierToken.toString()]: (state, { token }: SetCashierTokenPayload) => {
    return {
      ...state,
      cashierToken: token,
    };
  },
  [setCashierPayment.toString()]: (state) => {
    return {
      ...state,
      isLoading: true,
    };
  },
  [setCashierPaymentDone.toString()]: (state) => {
    return {
      ...state,
      cashierToken: undefined,
      isLoading: false,
    };
  },
  [clearOrderResponse.toString()]: (state) => {
    return {
      ...state,
      orderResponse: undefined,
    };
  },
  [submitSmsCode.toString()]: (state) => {
    return {
      ...state,
      hasSmsError: false,
    };
  },
  [setSmsError.toString()]: (state, payload) => {
    return {
      ...state,
      hasSmsError: payload.hasSmsError,
    };
  },
  [setSmsLoading.toString()]: (state, payload: SetSmsLoadingPayload) => {
    return {
      ...state,
      isSmsLoading: payload.loading,
    };
  },
  [setErrorType.toString()]: (state, payload: SetErrorTypePayload) => {
    return {
      ...state,
      errorType: payload.errorType,
      errorCode: payload.errorCode,
      errorChargeId: payload.errorChargeId,
      isLoading: false,
    };
  },
  [abortSmsVerification.toString()]: (state) => {
    return {
      ...state,
      isLoading: false,
      isSmsLoading: false,
      hasSmsError: false,
    };
  },
};

const reducer = createReducer<CheckoutState>(initialState, handlers);

export default reducer;
