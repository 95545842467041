import { createAction } from '../common/actionUtils';
import {
  CloseModalPayload,
  NavigatePayload,
  OpenModalPayload,
  SetPaymentMethodsPayload,
  SetOrganizationFullPayload,
} from './session.actions.types';

export const setOrganizationFull = createAction<SetOrganizationFullPayload>('SET_ORGANIZATION_FULL');
export const openModal = createAction<OpenModalPayload>('OPEN_MODAL');
export const closeModal = createAction<CloseModalPayload>('CLOSE_MODAL');
export const navigate = createAction<NavigatePayload>('NAVIGATE');
export const saveStateToSessionStorage = createAction('SAVE_ORDER_ITEMS_TO_SESSION_STORAGE');
export const clearSessionStorage = createAction('CLEAR_SESSION_STORAGE');
export const initApp = createAction('INIT_APP');
export const setPaymentMethods = createAction<SetPaymentMethodsPayload>('SET_PAYMENT_METHODS');
