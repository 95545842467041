import moment from 'moment';
import { createSelector } from 'reselect';
import { helpers } from 'wix-restaurants-js-sdk';
import { AppState } from '../createStore';
import { isChargeRestrictedByCouponHashCode } from '@wix/restaurants-client-logic';

export const couponChargesSelector = createSelector(
  (state: AppState) => state.session.menu.chargesV2,
  (state: AppState) => state.session.restaurant.timezone,
  (state: AppState) => state.checkout.dispatch,
  (state: AppState) => state.platformParams.isMobile,
  (state: AppState) => state.cart.orderItems,
  (state: AppState) => state.cart.coupon,
  (charges, timezone, dispatch, isMobile, orderItems, coupon) => {
    if (coupon?.type !== 'success' || !coupon.couponHashCode) {
      return {};
    }

    const chargesAndConditions = (charges || []).map((charge) => {
      const params = {
        charge,
        condition: charge.condition,
        deliveryTime: dispatch.time ? moment(dispatch.time).tz(timezone) : moment().tz(timezone),
        deliveryType: dispatch.type,
        platform: isMobile ? 'mobileweb' : 'web',
        orderItems,
        couponHashCode: coupon.couponHashCode,
      };

      let conditionResult = helpers.Condition.checkConditionWithReasons(params);

      if (conditionResult.value) {
        if (charge.state === 'closed') {
          conditionResult = {
            value: false,
          };
        } else if (helpers.ChargeV2.isRestrictedByOrderItems(params)) {
          conditionResult = {
            value: false,
            elaborateReasons: [{ reason: 'applyTo' }],
          };
        }
      }

      return {
        charge,
        conditionResult,
      };
    });

    const relevantChargeConditions = chargesAndConditions.filter((cc) =>
      isChargeRestrictedByCouponHashCode(cc.charge, coupon.couponHashCode),
    );

    // Might be multiple relevant charges (discount is cloned for multiple tax groups)
    const chargeAndCondition =
      relevantChargeConditions.find((c) => c.conditionResult.value) || relevantChargeConditions[0];

    if (!chargeAndCondition) {
      return { error: { reason: 'nonexistentCoupon' } };
    }

    if (!chargeAndCondition.conditionResult.value) {
      const reason = chargeAndCondition.conditionResult.elaborateReasons[0];
      return { charge: chargeAndCondition.charge, error: reason };
    }

    return { charge: chargeAndCondition.charge };
  },
);
