interface getBaseUrlForMappedServicesArguments {
  websiteUrl: string;
  isSSR: boolean;
}

export function getBaseUrlForMappedServices({ websiteUrl, isSSR }: getBaseUrlForMappedServicesArguments) {
  if (isSSR || !websiteUrl) {
    return 'https://www.wix.com';
  } else {
    const url = new URL(websiteUrl);
    return `${url.protocol}//${url.host}`;
  }
}
